
/* eslint-disable @typescript-eslint/camelcase */
import { ErrorMessages } from '@/helpers/constants'
import { Product } from '@/interfaces/product.interface'
import { CreateTicketData } from '@/interfaces/ticket.interface'
import {
  editTicket,
  generateTicket,
  getProductInformation,
} from '@/services/seller.service'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Validate } from 'vuelidate-property-decorators'
import { required } from 'vuelidate/lib/validators'

@Component
export default class QRStatus extends Vue {
  public product?: Product | {} = {}
  public ticketId?: number
  public isEdit = false

  @Validate({}) reference?: string
  @Validate({ required }) peopleQuantity: number = 1;
  @Validate({ required }) productStatus!: string
  @Validate({ required }) productPayment!: string

  @Prop() readonly headerText!: string

  async createTicket() {
    const loading = this.$loading.show()
    try {
      let newTicket
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const productPaymentMethods: any = {
        Efectivo: 'cash',
        Transferencia: 'transfer',
        Tarjeta: 'card',
      }
      const createTicketData: CreateTicketData = {
        product_id: (this.product as Product).id,
        quantity_persons: this.peopleQuantity,
        payment_status: 1,
        payment_method: productPaymentMethods['Efectivo'],
        reference: this.reference || null,
      }

      if (this.isEdit && this.ticketId) {
        newTicket = await editTicket(createTicketData, this.ticketId)
      } else {
        newTicket = await generateTicket(createTicketData)
      }
      loading.hide()

      this.$router.push({
        name: 'Compartir Link',
        params: { productId: newTicket.uuid },
      })
      Vue.$toast.success(this.isEdit ? 'Ticket editado correctamente!' : 'Ticket creado correctamente!')
    } catch (error) {
      if (error.response.status === 422) {
        Vue.$toast.warning(error.response.data.message)
        loading.hide()
        return
      }
      Vue.$toast.error(ErrorMessages.COMMON)
      loading.hide()
    }
  }

  async getProductInfo() {
    const loading = this.$loading.show()
    try {
      const { data, configs } = await getProductInformation(
        this.$route.params.productId
      )
      this.product = data
      loading.hide()
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
      loading.hide()
    }
  }

   // Go Back Function
   goBack() {
    if (!this.isEdit) {
      this.$router.push(`/seller/products/`);
    } else {
      this.$router.push('/seller/links/');
    }
  }

  mounted() {
    this.getProductInfo()

    const {
      reference,
      edit,
      productStatus,
      productPayment,
      quota,
      ticketId,
    } = this.$route.query
    this.isEdit = edit == 'true' ? true : false

    //this.productStatus = productStatus == "true" ? "Pagado" : "Por pagar";
    this.productStatus = 'Pagado'
    this.productPayment = 'Transferencia'
    this.reference = reference as string
    this.reference = reference as string

    this.peopleQuantity = parseInt(quota as string) || 1


    this.productPayment =
      (productPayment as string).charAt(0).toUpperCase() +
      (productPayment as string).slice(1)

    if (this.productPayment === 'Cash') {
      this.productPayment = 'Efectivo'
    } else if (this.productPayment === 'Transfer') {
      this.productPayment = 'Transferencia'
    } else if (this.productPayment === 'Card') {
      this.productPayment = 'Tarjeta'
    }

    this.ticketId = parseInt(ticketId as string)
  }


  get peopleQuantityErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.peopleQuantity.$dirty) return errors
    !this.$v.peopleQuantity.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
  get productStatusErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.productStatus.$dirty) return errors
    !this.$v.productStatus.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
  get productPaymentErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.productPayment.$dirty) return errors
    !this.$v.productPayment.required &&
      errors.push(ErrorMessages.COMMOM_FIELD_REQUIRED)
    return errors
  }
}
